cockpitApp.controller('checkoutController', [
  '$rootScope', '$scope','$filter', '$localStorage', '$mdDialog', '$timeout', '$interval', '$sce', '$httpParamSerializer', '$anchorScroll', 'gettextCatalog', 'navigationHelper', 'checkout', 'profile', 'basketHelper', 'baggageHelper', 'bookingHelper', 'componentHelper', 'functionalities', 'dataHelper', 'profileHelper', 'logsProvider', 'checkoutHelper',
  function($rootScope, $scope, $filter, $localStorage, $mdDialog, $timeout, $interval, $sce, $httpParamSerializer, $anchorScroll, gettextCatalog, navigationHelper, checkout, profile, basketHelper, baggageHelper, bookingHelper, componentHelper, functionalities, dataHelper, profileHelper, logsProvider, checkoutHelper)
  {
    $rootScope.appTitle = $rootScope.appName + ' - ' + gettextCatalog.getString('Checkout');
    $scope.adyenProcessPendingRequests = [];
    $scope.booking = $rootScope.booking;
    $scope.checkoutData = {};
    $scope.countriesScope = {};
    $scope.pciToken = null;
    $scope.passengerList = bookingHelper.getPassengersToDisplay();
    $scope.paymentCardSelected = null;
    $scope.saveProfileData = {
      'value': false
    };
    $scope.savePaymentCardToProfile = {
      'value': false
    };
    $scope.companyCreditCard = {
      'value': false
    };

    $scope.setCountries = function($type) {
      return $timeout(function() {
        $scope.countriesScope = $rootScope.countries;

        if ($type === 'phoneCountryCodeChanged') {
          $scope.phoneCountryCodeChanged = true;
        } else {
          $scope.countryCodeChanged = true
        }
      }, 400);
    };

    // TODO: update to keep alive first token when available COPIT-1953
    $rootScope.pciTokenInterval = $interval(function() {
      if ($scope.totalPrice > 0) {
        $scope.getPciToken($scope.companyCreditCard.value);
      }
    }, 1740000);

    $scope.getTotalPricePerType = function() {
      const basketPrice = basketHelper.getTotalPricePerType();
      $scope.totalPrice = basketPrice.totalPrice;
      $scope.totalFee = basketPrice.totalFee;
    };

    $scope.getPciToken = function(companyCreditCard) {
      var requestParams = {
        filekey: $scope.activeFilekey,
        lastname: $scope.activeLastname
      };

      checkout.getPciToken(requestParams).then(
        function successCallback (response) {
          if (response.meta && response.meta.success && response.data !== undefined) {
            $scope.pciToken = response.data;
            $scope.createIframeUrl(companyCreditCard);
          } else {
            $scope.pciIframeStatus = 'error';
          }
        },
        function errorCallback () {
          if (!$scope.isOffline) {
            $scope.getPciToken();
          } else {
            $scope.status = 'pciIframeUrlError';
          }
        }
      )
    };

    $scope.getCheckoutPaymentIframeUrl = function(companyCreditCard) {
      $scope.pciIframeStatus = 'loading';
      $scope.savePaymentCardToProfile.value = false;

      if (!$scope.pciToken) {
        $scope.getPciToken(companyCreditCard);
      } else {
        $scope.createIframeUrl(companyCreditCard);
      }
    };

    $scope.createIframeUrl = function(companyCreditCard) {
      $scope.companyCreditCard.value = companyCreditCard;

      if ($scope.companyCreditCard.value && $scope.pciToken !== null) {
        var localeStyle = $scope.activeLocale.split('_')[0].toUpperCase();
        var params = $httpParamSerializer({
          T: $scope.pciToken,
          C: $scope.companyCreditCard.value,
          css: 'cosmita' + localeStyle
        });

        var iframeUrl = traviceApiPciIframeUrl + '?' + params;

        $scope.iframeUrl = $sce.trustAsResourceUrl(iframeUrl);
        $scope.pciIframeStatus = 'success';
        $scope.checkPciIframeError($scope.companyCreditCard.value);
      } else {
        $scope.pciIframeStatus = 'error';
      }
    };

    $scope.checkPciIframeError = function (paymentCard) {
      setTimeout(function () {
        var iframe = document.getElementById('pci-iframe');

        try {
          // if page is not loading, location will keep showing about:blank
          if (iframe.contentWindow && iframe.contentWindow.location.href === 'about:blank') {
            var logRequestParams = {
              interactionName: 'checkPciIframeStatus',
              filekey: $rootScope.activeFilekey,
              lastname: $rootScope.activeLastname,
              language: gettextCatalog.getCurrentLanguage(),
              error: 'Webclient iframe not loading (' + paymentCard + ')',
              url: window.location.href,
            };

            logsProvider.logPciIframeError(logRequestParams);
            $scope.pciIframeStatus = 'error';
          }
        } catch(error) {
          // if iframe works, we do not have access contentWindow.location.href and land here.
        }
      }, 1000);
    };

    $scope.isCCinPaymentTypes = function(cc) {
      var paymentTypes = ($rootScope.booking && $rootScope.booking.paymentTypes && $rootScope.booking.paymentTypes.cc) || ['VI', 'CA'];

      return paymentTypes.includes(cc);
    };

    $scope.isNewPaymentAdded = function() {
      return $scope.companyCreditCard.value != null && !$scope.paymentCardSelected;
    }

    $scope.prepareCheckout = function() {
      $scope.hasPnrTimedOutSeatsInBasket = basketHelper.hasPnrTimedOutSeatsInBasket();

      if ($scope.totalPrice > 0) {
        profile.getProfilePayments().then(
          function successCallback(response) {
            if (response.meta && response.meta.success) {
              $scope.status = 'prepareCheckoutSuccess';
              $scope.profilePayments = response.data;

              if ($scope.profilePayments && !$scope.dropinEnabled()) {
                $scope.accordionPayment.collapse('pane-iframe-payment');
                $scope.accordionPayment.expand('pane-profile-payments');
              }

              $scope.$emit('checkoutReady');
            } else {
              $scope.status = 'prepareCheckoutError';
            }
          },
          function errorCallback() {
            if (!$scope.isOffline) {
              $scope.prepareCheckout();
            } else {
              $scope.status = 'prepareCheckoutError';
            }
          }
        )
      } else {
        $scope.$emit('checkoutReady');
        $scope.status = 'prepareCheckoutSuccess';
      }
    };

    $scope.resetInvoice = function(pane) {
      $scope.invoice = angular.copy($scope.checkoutData.invoice);
      $scope.checkoutForm.invoiceForm.$setDirty();

      if (pane && $scope.checkoutForm.invoiceForm.$valid) {
        pane.collapse();
      }
    };

    $scope.setInvoice = function() {
      var profileData = $scope.profile && $scope.profile.data;

      $scope.checkoutData.invoice = {
        salutation: '',
        firstName: profileData && profileData.firstName ? profileData.firstName : '',
        lastName: profileData && profileData.lastName ? profileData.lastName : '',
        email: profileData && profileData.email ? profileData.email : '',
        mobileCountryCode: profileData && profileData.mobileCountryCode ? profileData.mobileCountryCode : '',
        mobileNumber: profileData && profileData.mobileNumber ? profileData.mobileNumber : '',
        street: profileData && profileData.street ? profileData.street : '',
        streetNumber: profileData && profileData.streetNumber ? parseInt(profileData.streetNumber) : '',
        postcode: profileData && profileData.postcode ? profileData.postcode : '',
        addressAdditional: profileData && profileData.addressAdditional ? profileData.addressAdditional : '',
        company: profileData && profileData.company ? profileData.company : '',
        vatNumber: profileData && profileData.vatNumber ? profileData.vatNumber : '',
        city: profileData && profileData.city ? profileData.city : '',
        countryCode: profileData && profileData.countryCode ? profileData.countryCode : ''
      };

      $scope.invoice = angular.copy($scope.checkoutData.invoice);
      var profileDataCountryCode = profileData && profileData.countryCode;
      var profileDataMobileCountryCode = profileData &&  profileData.mobileCountryCode;
      $scope.profileCountryCode = $filter('filter')($rootScope.countries, {'code': profileDataCountryCode}, true)[0];
      $scope.profileMobileCountryCode = $filter('filter')($rootScope.countries, {'callingCode': profileDataMobileCountryCode}, true)[0];
    };

    $scope.isInvoiceValid = function() {
      var isInvoiceValid = $scope.invoice && $scope.invoice.street !== '' && $scope.invoice.streetNumber !== ''
        && $scope.invoice.postcode !== '' && $scope.invoice.city !== '' && $scope.invoice.countryCode !== ''
        && $scope.invoice.firstName !== '' && $scope.invoice.lastName !== '' && $scope.invoice.email !== '';

      isInvoiceValid = $scope.basket.transfers ? isInvoiceValid && $scope.invoice.salutation !== '' && $scope.invoice.mobileCountryCode !== '' && $scope.invoice.mobileNumber !== '' : isInvoiceValid;

      return isInvoiceValid;
    };

    $scope.$on('checkoutReady', function() {
      $scope.isCheckoutReady = true;

      if ($scope.isAccordionInvoiceReady) {
        $scope.prepareCheckoutInvoice();
      }

      if ($scope.isAccordionPaymentReady) {
        $scope.prepareCheckoutProfilePayments();
      }

      if ($scope.dropinEnabled()) {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('sessionId') && searchParams.has('redirectResult')) {
          $scope.catchRedirectResult(searchParams);
        } else {
          $scope.initAdyenSessionAngular();
        }
      }
    });

    $scope.$on('accordion-payment:onReady', function() {
      $scope.isAccordionPaymentReady = true;

      if ($scope.isCheckoutReady) {
        $scope.prepareCheckoutProfilePayments();
      }
    });

    $scope.$on('accordion-invoice:onReady', function() {
      $scope.isAccordionInvoiceReady = true;

      if ($scope.isCheckoutReady) {
        $scope.prepareCheckoutInvoice();
      }
    });

    $scope.prepareCheckoutProfilePayments = function() {
      if (!$scope.profilePayments && !$scope.dropinEnabled()) {
        $scope.accordionPayment.toggle('pane-iframe-payment');
      }
    };

    $scope.prepareCheckoutInvoice = function() {
      // to not reset it when checkout fails and user did not want to save his profile data
      if (!$scope.isInvoiceValid()) {
        $scope.setInvoice();
      }

      if (!$scope.isInvoiceValid()) {
        $scope.accordionInvoice.expand('invoice');
      } else {
        $scope.saveInvoice();
      }
    };

    $scope.$on('accordion-payment-card:onReady', function() {
      $scope.paymentCollapseCallback = function(index, id) {
        if (id === 'pane-profile-payments') {
          $scope.paymentCardSelected = null;
          $rootScope.$emit('closeAccordionPaymentCard');
        } else if (id === 'pane-iframe-payment') {
          $scope.companyCreditCard.value = false;
          $scope.savePaymentCardToProfile.value = false;
          delete $scope.iframeUrl;
          delete $scope.pciIframeStatus;
        }
      };
    });

    $scope.saveInvoice = function() {
      if ($scope.checkoutForm && $scope.checkoutForm.invoiceForm) {
        $scope.checkoutForm.invoiceForm.$setSubmitted();

        if ($scope.basket.transfers) {
          $scope.validateDropdown('salutation');
          $scope.validateDropdown('mobileCountryCode');
        }
        $scope.validateDropdown('countryCode');

        if ($scope.isInvoiceValid()) {
          $scope.accordionInvoice.collapse('invoice');
          $scope.checkoutData.invoice = angular.copy($scope.invoice);
        } else {
          $scope.accordionInvoice.expand('invoice');
        }
      }
    };

    $scope.validateDropdown = function(model) {
      $scope.checkoutForm.invoiceForm[model].$setDirty();
    };

    $scope.setSelectedPaymentCard = function(index, paymentCardId) {
      $scope.paymentCardSelected = paymentCardId === $scope.paymentCardSelected || !$scope.accordionPayment.hasExpandedPane() ? null : paymentCardId;
    };

    $scope.wiggleMessage = function() {
      $scope.animateMessage = false;

      $timeout(function() {
        $anchorScroll('messages');
        $scope.animateMessage = true;
      });
    };

    $scope.openServiceAgreementDialog = function(event) {
      componentHelper.openDialog('/element/dialog/service-agreement', event);
    };

    $scope.showAciTerms = function () {
      const departureDateTime = moment($scope.booking.departureDateTime);
      const threshold = moment().add(14,'days');

      return departureDateTime.isSameOrBefore(threshold) && !!$scope.basket.automaticCheckins;
    };

    $scope.handleSubmit = function(event) {
      if (!$scope.profile) {
        return navigationHelper.profileLoggedOutPostAction();
      }

      $scope.saveInvoice();
      $scope.checkoutForm.terms.$setDirty();
      $scope.checkoutForm.terms2.$setDirty();
      $scope.checkoutForm.terms3.$setDirty();

      if ($scope.showAciTerms()) {
        $scope.checkoutForm.aci.$setDirty();
      }

      $scope.hasPnrTimedOutSeatsInBasket = basketHelper.hasPnrTimedOutSeatsInBasket();

      if ($scope.hasPnrTimedOutSeatsInBasket || !checkoutHelper.isBasketValid($scope.basket, $scope.orderStatus)) {
        return $scope.wiggleMessage();
      }

      if ($scope.dropinEnabled()) {
        $scope.orderDropin(event);
      } else {
        $scope.order(event);
      }
    }

    $scope.order = function(event) {
      try {
        $scope.isCardMissing = $scope.totalPrice > 0 && !$scope.paymentCardSelected && !$scope.companyCreditCard.value;

        if ($scope.checkoutForm.$valid) {
          $scope.setBeforeUnload();
          componentHelper.openDialog('/element/dialog/checkout-process', event);

          var requestParams = $scope.getOrderPayload();

          $scope.status = 'processingOrders';

          checkout.order(requestParams).then(
            function successCallback(response) {
              $scope.orderStatus = {};
              var responseData = !response.status ? response.data : response.data && response.data.data;
              var responseMeta = !response.status ? response.meta : response.data && response.data.meta;

              if (responseMeta && responseData) {
                $scope.orderStatus = responseData;

                if (responseData.updatedBasket) {
                  $localStorage.cockpit.baskets[$rootScope.activeFilekey] = responseData.updatedBasket;
                  $rootScope.$emit('refreshBasket');
                }

                if (responseMeta.success && responseData.generalStatus === 'success') {
                  $interval.cancel($rootScope.pciTokenInterval);
                  navigationHelper.redirectWithLocale('/confirmation/:' + $scope.activeFilekey + '/:' + $scope.activeLastname);
                } else {
                  $scope.resetPaymentMethod(responseData.resetIframe);
                }
              } else {
                if (status !== 401) {
                  $scope.orderStatus.generalStatus = 'error';
                  var resetIframe = responseData ? responseData.resetIframe : true;
                  $scope.resetPaymentMethod(resetIframe);
                }
              }

              if (responseData) {
                var profileData = responseData.profileData;
                $rootScope.profile.data = profileData ? profileData : $rootScope.profile.data;
                profileHelper.updateSessionProfile();
              }

              $scope.resetBeforeUnload();
              $mdDialog.cancel();
            },
            function errorCallback() {
              $scope.orderStatus.generalStatus = 'error';
              $scope.resetBeforeUnload();
              $mdDialog.cancel();
            }
          );
        }
      } catch(e) {
        $scope.orderStatus.generalStatus = 'error';
        $scope.resetBeforeUnload();
        $mdDialog.cancel();
      }
    };

    $scope.redirectToProblematicSegmentSeatmap = function(seatType) {
      var problematicSegment = null;

      switch (seatType) {
        case 'timedOut':
          if ($rootScope.basket && $rootScope.basket.seats) {
            problematicSegment = $filter('toArray')($rootScope.basket.seats, true).find(function(segmentSeat) {
              return basketHelper.hasSegmentTimedOutSeatsInBasket(segmentSeat.$key);
            });
          }
          break;
        case 'underReserve':
          if ($rootScope.basket && $rootScope.basket.seats) {
            problematicSegment = $filter('toArray')($rootScope.basket.seats, true).find(function(segmentSeat) {
              return basketHelper.hasSegmentUnderReserveSeatsInBasket(segmentSeat.$key);
            });
          }
          break;
        default:
          if ($rootScope.booking && $rootScope.booking.segments) {
            if ($scope.orderStatus && $scope.orderStatus.items && $scope.orderStatus.items.seats) {
              problematicSegment = $rootScope.booking.segments.find(function(segment) {
                return segment.segmentExtendedNumber === Object.keys($scope.orderStatus.items.seats)[0];
              });
            } else {
              problematicSegment = segmentHelper.getFutureFlightSegments()[0];
            }
          }
          break;
      }

      navigationHelper.redirectToSeatmap(problematicSegment);
    };

    $scope.getOrderPayload = function() {
      var profileData = {
        firstName: $scope.checkoutData.invoice.firstName !== '' ? $scope.checkoutData.invoice.firstName : $scope.profile.data.firstName,
        lastName: $scope.checkoutData.invoice.lastName !== '' ? $scope.checkoutData.invoice.lastName : $scope.profile.data.lastName,
        mobileCountryCode: $scope.checkoutData.invoice.mobileCountryCode !== '' ? $scope.checkoutData.invoice.mobileCountryCode.split('_')[0] : null,
        mobileNumber: $scope.checkoutData.invoice.mobileNumber !== '' ? $scope.checkoutData.invoice.mobileNumber : $scope.profile.data.mobileNumber,
        street: $scope.checkoutData.invoice.street,
        streetNumber: $scope.checkoutData.invoice.streetNumber,
        addressAdditional: $scope.checkoutData.invoice.addressAdditional,
        postcode: $scope.checkoutData.invoice.postcode,
        city: $scope.checkoutData.invoice.city,
        countryCode: $scope.checkoutData.invoice.countryCode,
        company: $scope.checkoutData.invoice.company !== '' ? $scope.checkoutData.invoice.company: null,
        vatNumber: $scope.checkoutData.invoice.vatNumber !== '' ? $scope.checkoutData.invoice.vatNumber : null
      };

      var requestParams = {
        pnrIdentifier: $scope.booking.pnrIdentifier,
        filekey: $scope.activeFilekey,
        lastname: $scope.activeLastname,
        profileEmail: $scope.profile.data.email,
        basket: $scope.basket,
        locale: $scope.activeLocale,
        pciToken: $scope.pciToken,
        savePaymentCardToProfile: $scope.savePaymentCardToProfile.value,
        saveProfileData: $scope.saveProfileData.value,
        profilePaymentCardId: $scope.paymentCardSelected,
        subdomain: $rootScope.subdomain,
        purchaseOrder: {
          salutation: $scope.checkoutData.invoice.salutation !== '' ? $scope.checkoutData.invoice.salutation : $scope.profile.data.salutation,
          travelAgencyNumber: $scope.booking.travelAgencyNumber,
          pnrIdentifier: $scope.booking.pnrIdentifier,
          filekey: $scope.activeFilekey,
          lastNames: $scope.booking.lastNames,
          marketingAirlinesCodes: basketHelper.getBasketMarketingAirlinesCodes(),
          crs: $scope.booking.crs,
          email: $scope.checkoutData.invoice.email !== '' ? $scope.checkoutData.invoice.email : $scope.profile.data.email,
          totalPrice: $scope.totalPrice,
          currencyCode: $scope.booking.currencyCode,
          locale: $scope.activeLocale
        },
        purchasePayment: null
      };

      angular.merge(requestParams.purchaseOrder, profileData);

      if (requestParams.saveProfileData) {
          requestParams.profileData = profileData;
      }

      if ($scope.totalPrice > 0) {
        requestParams.purchasePayment = {
          provider: 'Ogone',
          ccCompanyCode: $scope.companyCreditCard.value || $filter('filter')($scope.profilePayments, {'id': $scope.paymentCardSelected})[0].companyCode
        };
      }

      return requestParams;
    };

    $scope.setBeforeUnload = function() {
      window.onbeforeunload = function() {
        return true;
      };
    };

    $scope.resetBeforeUnload = function() {
      window.onbeforeunload = undefined;
    };

    $scope.resetPaymentMethod = function(resetIframe) {
      $scope.prepareCheckout();
      $scope.wiggleMessage();

      if (resetIframe) {
        $scope.pciIframeStatus = 'loading';
        $scope.getPciToken($scope.companyCreditCard.value);
      }
    };

    $scope.getPassengerByPnrPosition = function(booking, pnrPosition, isInfantRestricted) {
      return bookingHelper.getPassengerByPnrPosition(booking, pnrPosition, isInfantRestricted);
    };

    $scope.removeSeatFromBasket = function(segmentExtendedNumber, pnrPosition, event) {
      basketHelper.removeSeatFromBasket(segmentExtendedNumber, pnrPosition, event);
    };

    $scope.removeExtraFromBasket = function(serviceType, pnrPosition, segmentExtendedNumber, ssrCode, event) {
      basketHelper.removeExtraFromBasket(serviceType, pnrPosition, segmentExtendedNumber, ssrCode, event);
    };

    $scope.removeBaggageFromBasket = function(pnrPosition, baggageCode, segmentPriceListCode, event) {
      basketHelper.removeBaggageFromBasket(pnrPosition, baggageCode, segmentPriceListCode, event);
    };

    $scope.removeAutomaticCheckinFromBasket = function(pnrPosition, event) {
      basketHelper.removeAutomaticCheckinFromBasket(pnrPosition, event);
    };

    $scope.removeInsuranceBaggageFromBasket = function(pnrPosition, event) {
      basketHelper.removeInsuranceBaggageFromBasket(pnrPosition, event);
    };

    $scope.removeTransfersFromBasket = function(pnrPosition, event) {
      basketHelper.removeTransfersFromBasket(pnrPosition, event);
    };

    $scope.removeTransferConnectionFromBasket = function(connectionId, pnrPosition, event) {
      basketHelper.removeTransferConnectionFromBasket(connectionId, pnrPosition, event);
    };

    var functionalitiesFetchedListener = $scope.$on('functionalitiesFetched', function (event, functionalityList) {
      if ((!functionalityList || (functionalityList && functionalityList.indexOf('shoppingEnabled') > -1)) && !$rootScope.functionalities[$rootScope.activeFilekey].shoppingEnabled) {
        navigationHelper.redirectWithLocale('/details/:' + $scope.activeFilekey + '/:' + $scope.activeLastname);
      }
    });

    var refreshBasketListener = $rootScope.$on('refreshBasket', function() {
      $rootScope.basket = $localStorage.cockpit.baskets[$scope.activeFilekey];
      $scope.getTotalPricePerType();
    });

    $scope.$on('$destroy', refreshBasketListener, functionalitiesFetchedListener, $interval.cancel($rootScope.pciTokenInterval));

    $scope.getFunctionalities = function() {
      // If one of the shoppping functionalities is not within the root functionalities, refresh the functionalities
      var shoppingFunctionalities = ['shoppingEnabled', 'seatDisplayEnabled', 'seatReservationChargeableEnabled', 'seatReservationNonChargeableEnabled', 'extrasEnabled', 'cashUpgradeEnabled', 'baggageEnabled', 'automaticCheckinEnabled', 'insuranceBlueribbonbagsEnabled', 'transferEnabled'];
      var incompleteFunctionalities = shoppingFunctionalities.some(function(functionality) {
        return !$rootScope.isFunctionalityEnabled(functionality);
      });

      if (incompleteFunctionalities) {
        functionalities.getFunctionalities(shoppingFunctionalities).then(
          function successCallback(response) {
            if (response.meta && response.meta.success && response.data) {
              $scope.prepareCheckout();
            }
          },
          function errorCallback() {
            $scope.status = 'prepareCheckoutError';
          }
        );
      } else {
        $scope.prepareCheckout();
      }
    };

    $scope.goToDetails = function(action) {
      navigationHelper.redirectWithLocale('/details/:' + $scope.activeFilekey + '/:' + $scope.activeLastname);
      dataHelper.setValue(action, 'action');
    };

    $scope.sendGATracking = function() {
      if (dataHelper.getValue('gaToCheckout')) {
        $rootScope.gaTracking('Checkout', 'click', dataHelper.getValue('gaToCheckout') + 'Success');
        dataHelper.setValue(false, 'gaToCheckout');
      }
    };

    $scope.showDeleteBaggageIcon = function(pnrPosition, segmentPriceListCode) {
      if ($rootScope.basket && $rootScope.basket.baggage && $rootScope.basket.baggage[pnrPosition]) {
        return baggageHelper.showDeleteBaggageIcon($rootScope.basket.baggage[pnrPosition], segmentPriceListCode);
      }
    };

    $scope.getAirlinesServiceAgreement = function() {
      var airlinesServiceAgreement = [];
      angular.forEach($rootScope.booking.segments, function (segment) {
        if (!airlinesServiceAgreement.includes(segment.marketingAirlineName)) {
          airlinesServiceAgreement.push(segment.marketingAirlineName);
        }
      });

      if ($scope.basket.automaticCheckins) {
        const provider = $rootScope.isInviaBrand() ? ' Invia Flights Germany GmbH' : ' Airobot';
        airlinesServiceAgreement.push(provider);
      }

      if ($scope.basket.insuranceBaggage) {
        airlinesServiceAgreement.push(' BlueRibbonBags');
      }

      return airlinesServiceAgreement.toString();
    };

    // Adyen Dropin Checkout
    $scope.dropinEnabled = function() {
      return ['244729', '160028', '160031', '160063', '160066', '160050'].includes($scope.booking.travelAgencyNumber) || $rootScope.isInviaBrand();
    }

    $scope.getOrderPayloadDropIn = function() {
      var profileData = {
        firstName: $scope.checkoutData.invoice.firstName !== '' ? $scope.checkoutData.invoice.firstName : $scope.profile.data.firstName,
        lastName: $scope.checkoutData.invoice.lastName !== '' ? $scope.checkoutData.invoice.lastName : $scope.profile.data.lastName,
        mobileCountryCode: $scope.checkoutData.invoice.mobileCountryCode !== '' ? $scope.checkoutData.invoice.mobileCountryCode.split('_')[0] : null,
        mobileNumber: $scope.checkoutData.invoice.mobileNumber !== '' ? $scope.checkoutData.invoice.mobileNumber : $scope.profile.data.mobileNumber,
        street: $scope.checkoutData.invoice.street,
        streetNumber: $scope.checkoutData.invoice.streetNumber,
        addressAdditional: $scope.checkoutData.invoice.addressAdditional,
        postcode: $scope.checkoutData.invoice.postcode,
        city: $scope.checkoutData.invoice.city,
        countryCode: $scope.checkoutData.invoice.countryCode || 'DE',
        company: $scope.checkoutData.invoice.company !== '' ? $scope.checkoutData.invoice.company: null,
        vatNumber: $scope.checkoutData.invoice.vatNumber !== '' ? $scope.checkoutData.invoice.vatNumber : null
      };

      var requestParams = {
        pnrIdentifier: $scope.booking.pnrIdentifier,
        filekey: $scope.activeFilekey,
        lastname: $scope.activeLastname,
        profileEmail: $scope.profile.data.email,
        basket: $scope.basket,
        locale: $scope.activeLocale,
        savePaymentCardToProfile: $scope.savePaymentCardToProfile.value,
        saveProfileData: $scope.saveProfileData.value,
        profilePaymentCardId: $scope.paymentCardSelected,
        subdomain: $rootScope.subdomain,
        currency: $scope.booking.currencyCode,
        adyenReference: $scope.adyenReference,
        shopperReference: $scope.shopperReference,
        purchaseOrder: {
          salutation: $scope.checkoutData.invoice.salutation !== '' ? $scope.checkoutData.invoice.salutation : $scope.profile.data.salutation,
          travelAgencyNumber: $scope.booking.travelAgencyNumber,
          pnrIdentifier: $scope.booking.pnrIdentifier,
          filekey: $scope.activeFilekey,
          lastNames: $scope.booking.lastNames,
          marketingAirlinesCodes: basketHelper.getBasketMarketingAirlinesCodes(),
          crs: $scope.booking.crs,
          email: $scope.checkoutData.invoice.email !== '' ? $scope.checkoutData.invoice.email : $scope.profile.data.email,
          totalPrice: $scope.totalPrice,
          currencyCode: $scope.booking.currencyCode,
          locale: $scope.activeLocale,
          countryCode: $scope.checkoutData.invoice.countryCode || 'DE'
        },
        purchasePayment: null
      };

      angular.merge(requestParams.purchaseOrder, profileData);

      if (requestParams.saveProfileData) {
        requestParams.profileData = profileData;
      }

      return requestParams;
    };

    $scope.orderDropin = function(event) {
      try {
        if ($scope.checkoutForm.$valid) {
          $scope.dropin.submit();
        }
      } catch(e) {
        $scope.orderStatus.generalStatus = 'error';
        $scope.resetBeforeUnload();
        $mdDialog.cancel();
      }
    };

    $scope.requestParams = {};

    function handleOnPaymentCompleted(result, component) {
      $scope.setBeforeUnload();
      $scope.orderStatus = {};

      if (result.resultCode === 'Authorised') {
        componentHelper.openDialog('/element/dialog/checkout-process', event);
        $scope.status = 'processingOrders';
        var requestParams = $scope.getOrderPayloadDropIn();

        $scope.adyenProcessPendingRequests.push("order");

        checkout.adyenOrder(requestParams).then(
          function successCallback (response) {
            $scope.adyenProcessPendingRequests = [];
            var responseData = !response.status ? response.data : response.data && response.data.data;
            var responseMeta = !response.status ? response.meta : response.data && response.data.meta;

            if (responseMeta && responseData) {
              $scope.orderStatus = responseData;

              if (responseMeta.success && responseData.generalStatus === 'success') {
                navigationHelper.redirectWithLocale('/confirmation/:' + $scope.activeFilekey + '/:' + $scope.activeLastname);
              } else {
                if (responseData.updatedBasket) {
                  $localStorage.cockpit.baskets[$rootScope.activeFilekey] = responseData.updatedBasket;
                  $rootScope.$emit('refreshBasket');
                }

                $scope.reloadDropin();
              }

              var profileData = responseData.profileData;
              $rootScope.profile.data = profileData ? profileData : $rootScope.profile.data;
              profileHelper.updateSessionProfile();
            } else {
              $scope.orderStatus.generalStatus = 'error';
              $scope.status = 'error';
              $scope.reloadDropin();
            }

            $scope.resetBeforeUnload();
            $mdDialog.cancel();
          },
          function errorCallback() {
            $scope.orderStatus.generalStatus = 'error';
            $scope.status = 'error';
            $scope.resetBeforeUnload();
            $mdDialog.cancel();
            $scope.reloadDropin();
          }
        );
      }
      else {
        $scope.orderStatus.generalStatus = 'error';
        $scope.status = 'error';
        $scope.resetBeforeUnload();
        $mdDialog.cancel();
        $scope.reloadDropin();
      }
    }

    function handleOnError(error, component) {
      // Called when Drop-in detects an invalid card number, invalid expiry date, or incomplete field. Called again when errors are cleared.
      // https://docs.adyen.com/payment-methods/cards/web-drop-in/#events
      console.error(error.name, error.message, error.stack, component);
    }

    $scope.catchRedirectResult = function (params) {
      const sessionId = params.get('sessionId');
      const redirectResult = params.get('redirectResult');

      const config = JSON.parse(sessionStorage.getItem('checkoutConfig_' + sessionId));

      const configuration = Object.assign(config, {
        session: { id: sessionId },
        onPaymentCompleted: handleOnPaymentCompleted,
        onError: handleOnError
      });

      AdyenCheckout(configuration).then(function (checkout) {
        checkout.submitDetails({ details: { redirectResult: redirectResult } });
      });
    }

    //angular works with scripts tag
    $scope.initAdyenSessionAngular = function () {
      const adyenRequestParams = $scope.getAdyenRequestParams();

      checkout.getSession(adyenRequestParams).then(
          function successCallback (response) {
            if (response.meta && response.meta.success && response.data) {
              const sessionData = { id: response.data.id, sessionData: response.data.sessionData };
              const sessionId = response.data.id;
              $scope.adyenReference = response.data.reference;
              $scope.shopperReference = response.data.shopperReference;

              const configuration = {
                environment: response.data.environment,
                showStoredPaymentMethods: true,
                clientKey: response.data.clientKey,
                analytics: {
                  enabled: false
                },
                session: sessionData,
                onPaymentCompleted: handleOnPaymentCompleted,
                onError: handleOnError,
                beforeSubmit: handleBeforeSubmit,
                showPayButton: false,
                paymentMethodsConfiguration: {
                  card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    enableStoreDetails: true,
                    showStoredPaymentMethods: true,
                  }
                },
              };

              sessionStorage.setItem('checkoutConfig_' + sessionId, JSON.stringify(configuration));

              $scope.loadDropIn(configuration);
            } else {
              $scope.status = 'prepareCheckoutError';
            }
          },
          function errorCallback (er) {
            $scope.status = 'prepareCheckoutError';
          }
      );
    };

    $scope.getAdyenRequestParams = function () {
      return {
        countryCode: $scope.profileCountryCode ? $scope.profileCountryCode.code : null,
        locale: $scope.activeLocale,
        basket: $scope.basket,
        filekey: $scope.activeFilekey,
        lastname: $scope.activeLastname,
        purchaseOrder: {
          totalPrice: $scope.totalPrice,
          currencyCode: $scope.booking.currencyCode,
        },
        returnUrl: document.URL
      };
    };

    $scope.dropin = null;

    function handleBeforeSubmit (data, component, actions) {
      if ($scope.adyenProcessPendingRequests.length === 0) {
        actions.resolve(data);
        $scope.adyenProcessPendingRequests.push("payments");
      }
    }

    $scope.reloadDropin = function () {
      $scope.status = "prepareCheckoutSuccess";
      $scope.adyenProcessPendingRequests = [];
      $scope.initAdyenSessionAngular();
    }

    $scope.loadDropIn = function (configuration) {
      return new Promise(function (resolve, reject) {
        if ($scope.dropin !== null) {
          $scope.dropin.unmount();
        }
        var checkout = AdyenCheckout(configuration);
        resolve(checkout);
      }).then(function (checkout) {
        $scope.dropin = checkout.create('dropin').mount('#dropin-container');
      }).catch(function (error) {
        location.reload();
      });
    }

    // init functions
    $scope.getTotalPricePerType();
    $scope.getFunctionalities();
    $scope.sendGATracking();
  }
]);
