cockpitApp.factory('basketHelper', [
  '$rootScope', '$filter', '$localStorage', 'navigationHelper', 'profileHelper', 'componentHelper', 'dataHelper', '$mdComponentRegistry', '$route',
  function($rootScope, $filter, $localStorage, navigationHelper, profileHelper, componentHelper, dataHelper, $mdComponentRegistry, $route) {
    var factory = {};

    factory.validateBasket = function() {
      var self = this;

      if ($rootScope.booking && $rootScope.basket) {
        angular.forEach($rootScope.booking.segments, function(segment) {
          angular.forEach(segment.passengers, function(passenger) {
            if (segment.statusCode === 'cancelled') {
              if ($localStorage.cockpit.baskets[$rootScope.activeFilekey].seats && $localStorage.cockpit.baskets[$rootScope.activeFilekey].seats[segment.segmentExtendedNumber]) {
                delete $localStorage.cockpit.baskets[$rootScope.activeFilekey].seats[segment.segmentExtendedNumber];
              }
              if ($localStorage.cockpit.baskets[$rootScope.activeFilekey].baggage && $localStorage.cockpit.baskets[$rootScope.activeFilekey].baggage[passenger.pnrPosition]) {
                delete $localStorage.cockpit.baskets[$rootScope.activeFilekey].baggage[passenger.pnrPosition];
              }
            }

            if (passenger.seatRequest && $rootScope.basket && $rootScope.basket.seats && $rootScope.basket.seats[segment.segmentExtendedNumber]) {
              angular.forEach($rootScope.basket.seats[segment.segmentExtendedNumber].passengers, function(basketSeatPassenger, pnrPosition) {
                // remove this seat if present in basket for another passenger in that segment
                // remove this passenger's seat in basket for that segment
                if ($filter('ltrim')(basketSeatPassenger.seatNumber, '0') === $filter('ltrim')(passenger.seatRequest.seat, '0')
                  || $rootScope.basket.seats[segment.segmentExtendedNumber].passengers[passenger.pnrPosition]) {
                  self.removeSeatFromBasket(segment.segmentExtendedNumber, pnrPosition);
                }
              });
            }

            angular.forEach(passenger.specialServiceRequests, function(specialServiceRequest) {
              if ($rootScope.basket && $rootScope.basket.baggage) {
                angular.forEach($rootScope.basket.baggage, function(baggage, pnrPosition) {
                  angular.forEach(baggage, function(baggageDetails, baggageCode) {
                    angular.forEach(baggageDetails.segmentPriceList, function(segmentPriceList, segmentPriceListCode) {
                      if (specialServiceRequest.code === segmentPriceList.ssrCode && pnrPosition === passenger.pnrPosition) {
                        self.removeBaggageFromBasket(passenger.pnrPosition, baggageCode, segmentPriceListCode);
                      }
                    });
                  });
                });
              }
            });
          });
        });
      }
    };

    factory.getBasketMarketingAirlinesCodes = function() {
      var marketingAirlinesCodes = {
        seats: {},
        baggage: {},
        automaticCheckins: {},
        insuranceBaggage: {},
        cashUpgrade: {},
        extras: {}
      };

      angular.forEach($rootScope.basket, function(typeElements, type) {
        switch (type) {
          case 'seats':
            angular.forEach(typeElements, function(segmentSeat) {
              marketingAirlinesCodes['seats'][segmentSeat.marketingAirlineCode] = marketingAirlinesCodes['seats'][segmentSeat.marketingAirlineCode] || 0;
              marketingAirlinesCodes['seats'][segmentSeat.marketingAirlineCode] += $filter('count')(segmentSeat.passengers);
            });
            break;
          case 'baggage':
            angular.forEach(typeElements, function(passengerBaggage) {
              angular.forEach(passengerBaggage, function(baggageElement) {
                angular.forEach(baggageElement.segmentPriceList, function(segmentPrice) {
                  marketingAirlinesCodes['baggage'][segmentPrice.airlineCode] = marketingAirlinesCodes['baggage'][segmentPrice.airlineCode] || 0;
                  marketingAirlinesCodes['baggage'][segmentPrice.airlineCode] += 1;
                });
              });
            });
            break;
          case 'automaticCheckins':
            angular.forEach($rootScope.booking.segments, function(segment) {
              marketingAirlinesCodes['automaticCheckins'][segment.marketingAirlineCode] = marketingAirlinesCodes['automaticCheckins'][segment.marketingAirlineCode] || 0;
              marketingAirlinesCodes['automaticCheckins'][segment.marketingAirlineCode] += 1;
            });
            break;
          case 'insuranceBaggage':
            angular.forEach($rootScope.booking.segments, function(segment) {
              marketingAirlinesCodes['insuranceBaggage'][segment.marketingAirlineCode] = marketingAirlinesCodes['insuranceBaggage'][segment.marketingAirlineCode] || 0;
              marketingAirlinesCodes['insuranceBaggage'][segment.marketingAirlineCode] += 1;
            });
            break;
          case 'cashUpgrade':
            angular.forEach($rootScope.booking.segments, function(segment) {
              marketingAirlinesCodes['cashUpgrade'][segment.marketingAirlineCode] = marketingAirlinesCodes['cashUpgrade'][segment.marketingAirlineCode] || 0;
              marketingAirlinesCodes['cashUpgrade'][segment.marketingAirlineCode] += 1;
            });
            break;
          case 'extras':
            angular.forEach($rootScope.booking.segments, function(segment) {
              marketingAirlinesCodes['extras'][segment.marketingAirlineCode] = marketingAirlinesCodes['extras'][segment.marketingAirlineCode] || 0;
              marketingAirlinesCodes['extras'][segment.marketingAirlineCode] += 1;
            });
            break;
        }
      });

      return marketingAirlinesCodes;
    };

    factory.getTotalPricePerType = function(types) {
      var totalPrice = 0.00;
      var totalFee = 0.00;

      angular.forEach($rootScope.basket, function(ancillary, type) {
        var isTypeRequested = !types || (types && types.indexOf(type) !== -1);

        if (isTypeRequested && ancillary) {
          if (type === 'seats') {
            angular.forEach(ancillary, function(segment, segmentNumber) {
              angular.forEach(segment.passengers, function(passenger, pnrPosition) {
                totalPrice += parseFloat(passenger.price);
                totalFee += parseFloat(passenger.purchaseFee);
              });
            });
          }

          if (['extras', 'cashUpgrade'].includes(type)) {
            angular.forEach(ancillary, function(segment, segmentNumber) {
              angular.forEach(segment.passengers, function(passengerSSRs, pnrPosition) {
                angular.forEach(passengerSSRs, function(passengerSSR, ssrCode) {
                  totalPrice += parseFloat(passengerSSR.price);
                  totalFee += parseFloat(passengerSSR.purchaseFee);
                });
              });
            });
          }

          if (type === 'baggage') {
            angular.forEach(ancillary, function(ssr, segmentNumber) {
              angular.forEach(ssr, function(ssrElements, ssrCode) {
                angular.forEach(ssrElements.segmentPriceList, function(ssrElement, newSsrCode) {
                  totalPrice += parseFloat(ssrElement.purchasePrice.value);
                  totalFee += parseFloat(ssrElement.purchaseFee);
                });
              });
            });
          }

          if (type === 'automaticCheckins') {
            angular.forEach(ancillary.passengers, function(passengerAci, pnrPosition) {
              totalPrice += parseFloat(passengerAci.price);
              totalFee += parseFloat(passengerAci.purchaseFee);
            });
          }

          if (type === 'insuranceBaggage') {
            angular.forEach(ancillary, function(passengerBaggageInsurance, pnrPosition) {
              totalPrice += parseFloat(passengerBaggageInsurance.price);
              totalFee += parseFloat(passengerBaggageInsurance.purchaseFee);
            });
          }

          if (type === 'transfers') {
            angular.forEach(ancillary, function(connection, connectionId) {
              angular.forEach(connection.passengers, function(passenger, pnrPosition) {
                totalPrice += parseFloat(passenger.price);
              });
            });
          }
        }
      });

      if (!!types) {
        return (Math.round(totalPrice * 100) / 100);
      } else {
        return {
          totalPrice: (Math.round(totalPrice * 100) / 100),
          totalFee: (Math.round(totalFee * 100) / 100)
        };
      }
    };

    factory.getNumberOfSeatsInBasket = function() {
      var numberOfSeats = 0;

      if ($rootScope.basket) {
        angular.forEach($rootScope.basket.seats, function(segmentPnrSeat) {
          numberOfSeats += $filter('count')(segmentPnrSeat.passengers);
        });
      }

      return numberOfSeats;
    };

    factory.getNumberOfSeatsInBasketPerSegment = function(segmentNumber) {
      var numberOfSeats = 0;

      if ($rootScope.basket && $rootScope.basket.seats && $rootScope.basket.seats[segmentNumber]) {
        numberOfSeats = $filter('count')($rootScope.basket.seats[segmentNumber].passengers);
      }

      return numberOfSeats;
    };

    factory.getNumberOfExtrasInBasket = function(serviceType) {
      var numberOfExtras = 0;

      if ($rootScope.basket) {
        angular.forEach($rootScope.basket[serviceType], function(segmentPnrExtra) {
          angular.forEach(segmentPnrExtra.passengers, function(passengerExtras) {
            numberOfExtras += $filter('count')(passengerExtras);
          });
        });
      }

      return numberOfExtras;
    };

    factory.getNumberOfExtrasInBasketPerSegment = function(segmentNumber, serviceType) {
      var numberOfExtrasPerSegment = 0;

      if (segmentNumber && $rootScope.basket && $rootScope.basket[serviceType] && $rootScope.basket[serviceType][segmentNumber]) {
        angular.forEach($rootScope.basket[serviceType][segmentNumber].passengers, function(passengerExtras) {
          numberOfExtrasPerSegment += $filter('count')(passengerExtras);
        });
      }

      return numberOfExtrasPerSegment;
    };

    factory.getNumberOfBaggageInBasket = function() {
      var numberOfBaggage = 0;

      if ($rootScope.basket) {
        angular.forEach($rootScope.basket.baggage, function(pnrBaggage) {
          angular.forEach(pnrBaggage, function(passengerBaggage) {
            numberOfBaggage += $filter('count')(passengerBaggage.segmentPriceList);
          });
        });
      }

      return numberOfBaggage;
    };

    factory.getNumberOfAutomaticCheckinsInBasket = function() {
      var numberOfAutomaticCheckins = 0;

      if ($rootScope.basket && $rootScope.basket.automaticCheckins) {
        numberOfAutomaticCheckins = $filter('count')($rootScope.basket.automaticCheckins.passengers);
      }

      return numberOfAutomaticCheckins;
    };

    factory.getNumberOfInsuranceBaggageInBasket = function() {
      var numberOfInsuranceBaggage = 0;

      if ($rootScope.basket && $rootScope.basket.insuranceBaggage) {
        numberOfInsuranceBaggage = $filter('count')($rootScope.basket.insuranceBaggage);
      }

      return numberOfInsuranceBaggage;
    };

    factory.getNumberOfTransfersInBasket = function() {
      var numberOfTransfers = 0;

      if ($rootScope.basket && $rootScope.basket.transfers) {
        angular.forEach($rootScope.basket.transfers, function(transfer) {
          numberOfTransfers += $filter('count')(transfer.passengers);
        });
      }

      return numberOfTransfers;
    };

    factory.getNumberOfElementsInBasket = function() {
      return this.getNumberOfSeatsInBasket()
        + this.getNumberOfExtrasInBasket('extras')
        + this.getNumberOfExtrasInBasket('cashUpgrade')
        + this.getNumberOfBaggageInBasket()
        + this.getNumberOfAutomaticCheckinsInBasket()
        + this.getNumberOfInsuranceBaggageInBasket()
        + this.getNumberOfTransfersInBasket();
    };

    factory.isBasketObsolete = function(booking) {
      return booking && (booking.cancelled || $filter('isPast')(booking.arrivalDateTime, booking.arrivalTimezone) || !booking.travelAgencyNumber);
    };

    factory.isShoppingEnabled = function() {
      if ($rootScope.activeFilekey) {
        return $rootScope.isFunctionalityEnabled('shoppingEnabled');
      }

      return !!($rootScope.travelAgency.data && $rootScope.travelAgency.data.functionalities && $rootScope.travelAgency.data.functionalities.shoppingEnabled);
    };

    factory.redirectIfShoppingIsDisabled = function() {
      var self = this;

      $rootScope.$on('travelAgencyFetched', function() {
        if (!self.isShoppingEnabled()) {
          navigationHelper.bookingOverviewPreCheck('active', 'past');
        }
      });
    };

    factory.toggleBasket = function(forceOpen) {
      if (this.isShoppingEnabled()) {
        $mdComponentRegistry.when('menuRightSidenav').then(function(menuRightSidenav) {
          if (forceOpen) {
            menuRightSidenav.open();
          } else {
            menuRightSidenav.toggle();
          }

          if (menuRightSidenav.isOpen()) {
            $rootScope.$emit('refreshBasket', true);
          }
        });
      }
    };

    factory.getFunctionalityDisabledSegments = function(functionality) {
      if (functionality && $rootScope.functionalities[$rootScope.activeFilekey][functionality]) {
        var segmentNumbers = Object.keys($rootScope.functionalities[$rootScope.activeFilekey][functionality]);

        return segmentNumbers.filter(function(segmentNumber) {
          return !$rootScope.functionalities[$rootScope.activeFilekey][functionality][segmentNumber];
        });
      }
    };

    factory.hasBasketSeatsByTypePerSegment = function(basket, functionality) {
      if (basket && functionality) {
        var seatReservationDisabledSegments = this.getFunctionalityDisabledSegments(functionality);

        return basket.seats && $filter('toArray')(basket.seats, true).some(function(seatSegment) {
          return seatReservationDisabledSegments && seatReservationDisabledSegments.includes(seatSegment.$key) && $filter('toArray')(seatSegment.passengers).some(function(passengerSeat) {
            return (functionality === 'seatReservationNonChargeableEnabled' && !passengerSeat.originalChargeable) ||
              (functionality === 'seatReservationChargeableEnabled' && passengerSeat.originalChargeable);
          });
        });
      }
    };

    factory.hasBasketExtrasByServiceTypePerSegment = function(basket, functionality, serviceType) {
      if (basket && functionality) {
        var extrasDisabledSegments = this.getFunctionalityDisabledSegments(functionality);

        return basket[serviceType] && $filter('toArray')(basket[serviceType], true).some(function(extraSegment) {
          return extrasDisabledSegments && extrasDisabledSegments.includes(extraSegment.$key);
        });
      }
    };

    factory.removeSeatFromBasketByTypePerSegment = function(functionality) {
      var self = this;
      var seatReservationDisabledSegments = self.getFunctionalityDisabledSegments(functionality);

      angular.forEach($rootScope.basket.seats, function(seatSegment, segmentNumber) {
       if (seatReservationDisabledSegments && seatReservationDisabledSegments.includes(segmentNumber)) {
         angular.forEach(seatSegment.passengers, function(passenger, pnrPosition) {
           if ((functionality === 'seatReservationNonChargeableEnabled' && !passenger.originalChargeable) || (functionality === 'seatReservationChargeableEnabled' && passenger.originalChargeable)) {
             self.removeSeatFromBasket(segmentNumber, pnrPosition);
           }
        });
       }
      });
    };

    factory.removeSeatFromBasket = function(segmentExtendedNumber, pnrPosition, event) {
      // Stops the propagation of the click event in the basket to avoid opening the seatmap when removing a seat.
      if (event) {
        event.stopPropagation();
      }

      if (segmentExtendedNumber && $localStorage.cockpit.baskets[$rootScope.activeFilekey] && $localStorage.cockpit.baskets[$rootScope.activeFilekey].seats) {
        var segmentBasketSeats = $localStorage.cockpit.baskets[$rootScope.activeFilekey].seats[parseInt(segmentExtendedNumber)];

        if (pnrPosition) {
          delete segmentBasketSeats.passengers[pnrPosition];
        }

        if (!pnrPosition || $filter('isEmpty')(segmentBasketSeats.passengers)) {
          delete $localStorage.cockpit.baskets[$rootScope.activeFilekey].seats[parseInt(segmentExtendedNumber)];
        }

        this.removeBasket('seats');
      }
    };

    factory.removeExtraFromBasket = function(serviceType, pnrPosition, segmentExtendedNumber, ssrCode, event) {
      var self = this;

      if (event) {
        event.stopPropagation();
      }

      if (serviceType === 'cashUpgrade') {
        angular.forEach($rootScope.booking.passengers, function(passenger) {
          self.removeExtra(serviceType, passenger.pnrPosition, segmentExtendedNumber, ssrCode);
        });
      } else {
        self.removeExtra(serviceType, pnrPosition, segmentExtendedNumber, ssrCode);
      }
    };

    factory.removeExtra = function(serviceType, pnrPosition, segmentExtendedNumber, ssrCode) {
      if (pnrPosition && segmentExtendedNumber && ssrCode && $localStorage.cockpit.baskets[$rootScope.activeFilekey] && $localStorage.cockpit.baskets[$rootScope.activeFilekey][serviceType]) {
        var segmentBasketExtras = $localStorage.cockpit.baskets[$rootScope.activeFilekey][serviceType][parseInt(segmentExtendedNumber)];

        if (segmentBasketExtras && segmentBasketExtras.passengers && serviceType === 'cashUpgrade') {
          angular.forEach(segmentBasketExtras.passengers, function (passengerBasket, pnrPosition) {
            delete segmentBasketExtras.passengers[pnrPosition][ssrCode];
          });
        } else {
          delete segmentBasketExtras.passengers[pnrPosition][ssrCode];
        }

        if ($filter('isEmpty')(segmentBasketExtras.passengers[pnrPosition])) {
          delete $localStorage.cockpit.baskets[$rootScope.activeFilekey][serviceType][parseInt(segmentExtendedNumber)].passengers[pnrPosition];
        }

        if ($filter('isEmpty')(segmentBasketExtras.passengers)) {
          delete $localStorage.cockpit.baskets[$rootScope.activeFilekey][serviceType][parseInt(segmentExtendedNumber)];
        }

        this.removeBasket(serviceType);
      }
    };

    factory.removeBaggageFromBasket = function(pnrPosition, baggageCode, segmentPriceListCode, event) {
      if (event) {
        event.stopPropagation();
      }

      if (pnrPosition && baggageCode && segmentPriceListCode) {
        var passengerBasketBaggage = $localStorage.cockpit.baskets[$rootScope.activeFilekey].baggage[pnrPosition];

        if (passengerBasketBaggage) {
          var segmentPriceList = passengerBasketBaggage[baggageCode].segmentPriceList;

          delete segmentPriceList[segmentPriceListCode];

          if ($filter('isEmpty')(segmentPriceList)) {
            delete $localStorage.cockpit.baskets[$rootScope.activeFilekey].baggage[pnrPosition][baggageCode];
          }

          if ($filter('isEmpty')(passengerBasketBaggage)) {
            delete $localStorage.cockpit.baskets[$rootScope.activeFilekey].baggage[pnrPosition];
          }
        }

        this.removeBasket('baggage');
      }
    };

    factory.removeAutomaticCheckinFromBasket = function(pnrPosition, event) {
      if (event) {
        event.stopPropagation();
      }

      if (pnrPosition && $localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins) {
        var passengerBasketAutomaticCheckin = $localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins.passengers[pnrPosition];

        if (passengerBasketAutomaticCheckin) {
          delete $localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins.passengers[pnrPosition];
        }

        if ($filter('isEmpty')($localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins.passengers)) {
          delete $localStorage.cockpit.baskets[$rootScope.activeFilekey].automaticCheckins;
        }

        this.removeBasket('automaticCheckins');
      }
    };

    factory.removeInsuranceBaggageFromBasket = function(pnrPosition, event) {
      if (event) {
        event.stopPropagation();
      }

      if (pnrPosition && $localStorage.cockpit.baskets[$rootScope.activeFilekey].insuranceBaggage) {
        var insuranceBaggage = $localStorage.cockpit.baskets[$rootScope.activeFilekey].insuranceBaggage[pnrPosition];

        if (insuranceBaggage) {
          delete $localStorage.cockpit.baskets[$rootScope.activeFilekey].insuranceBaggage[pnrPosition];
        }

        if ($filter('isEmpty')($localStorage.cockpit.baskets[$rootScope.activeFilekey].insuranceBaggage)) {
          delete $localStorage.cockpit.baskets[$rootScope.activeFilekey].insuranceBaggage;
        }

        this.removeBasket('insuranceBaggage');
        $rootScope.$apply();
      }
    };

    factory.removeTransferConnectionFromBasket = function(connectionId, pnrPosition, event) {
      if (event) {
        event.stopPropagation();
      }

      if (pnrPosition && connectionId && $localStorage.cockpit.baskets[$rootScope.activeFilekey] && $localStorage.cockpit.baskets[$rootScope.activeFilekey].transfers) {
        var basketConnection = $localStorage.cockpit.baskets[$rootScope.activeFilekey].transfers[connectionId];

        if (basketConnection && basketConnection.passengers) {
          delete basketConnection.passengers[pnrPosition];

          basketConnection.pax = $filter('count')(basketConnection.passengers);

          if ($filter('isEmpty')(basketConnection.passengers)) {
            delete $localStorage.cockpit.baskets[$rootScope.activeFilekey].transfers[connectionId];
          }
        }

        this.removeBasket('transfers');
      }
    };

    factory.removeBasket = function(lastTypeRemoved, force) {
      if ($filter('isEmpty')($localStorage.cockpit.baskets[$rootScope.activeFilekey][lastTypeRemoved]) || force) {
        delete $localStorage.cockpit.baskets[$rootScope.activeFilekey][lastTypeRemoved];
      }

      if ($filter('isEmpty')($localStorage.cockpit.baskets[$rootScope.activeFilekey])) {
        delete $localStorage.cockpit.baskets[$rootScope.activeFilekey];
      }

      $rootScope.$emit('refreshBasket');
    };

    factory.bookingHasBasketCheck = function() {
      var filekey = navigationHelper.normalizeFilekey($route.current.params.filekey);
      var lastname = $route.current.params.lastname ? $route.current.params.lastname.replace(':', '') : null;

      if (filekey && !$localStorage.cockpit.baskets[filekey] || ($localStorage.cockpit.baskets[filekey] && $filter('isEmpty')($localStorage.cockpit.baskets[filekey]))) {
        if (lastname) {
          navigationHelper.redirectWithLocale('/details/:' + filekey + '/:' + lastname);
        } else {
          navigationHelper.redirectWithLocale('/');
        }
      }
    };

    factory.displayGoToCheckoutOnBottomSection = function() {
      return !$rootScope.isLargeScreen && $rootScope.isFunctionalityEnabled('shoppingEnabled') && !$filter('isEmpty')($localStorage.cockpit.baskets[$rootScope.activeFilekey]) && $rootScope.isPage('details');
    };

    factory.redirectToCheckout = function(event) {
      if (!profileHelper.isLoggedIn()) {
        var dialogData = {};
        dialogData.onShowing = 'loginToCheckout';
        componentHelper.openDialog('/element/dialog/profile/main', event, dialogData);
      } else {
        dataHelper.setValue('detailPageToCheckout', 'gaToCheckout');
        componentHelper.openDialog('/element/dialog/confirm-checkout', event, null);
      }
    };

    factory.hasPnrTimedOutSeatsInBasket = function() {
      var self = this;

      var basketSegmentsSeatsWithTimedOutSeats = $rootScope.basket.seats && $filter('toArray')($rootScope.basket.seats, true).filter(function(segmentSeat) {
        return self.hasSegmentTimedOutSeatsInBasket(segmentSeat.$key);
      });

      return !$filter('isEmpty')(basketSegmentsSeatsWithTimedOutSeats);
    };

    factory.hasSegmentTimedOutSeatsInBasket = function(segmentNumber) {
      if ($rootScope.basket && $rootScope.basket.seats && $rootScope.basket.seats[segmentNumber]) {
        var basketSeatsWithTimedOutSeats = $filter('toArray')($rootScope.basket.seats[segmentNumber].passengers, true).filter(function(passengerSeat) {
          var isSeatTimeoutExpired = $filter('dateTimeDiff')(null, passengerSeat.dateTimeAddedToBasket, 'minutes') > 60;

          if (isSeatTimeoutExpired) {
            $localStorage.cockpit.baskets[$rootScope.activeFilekey].seats[segmentNumber].passengers[passengerSeat.$key].isTimedOut = true;
          }

          return isSeatTimeoutExpired;
        });

        return !$filter('isEmpty')(basketSeatsWithTimedOutSeats);
      }
    };

    factory.hasSegmentUnderReserveSeatsInBasket = function(segmentNumber) {
      if ($rootScope.basket && $rootScope.basket.seats && $rootScope.basket.seats[segmentNumber]) {
        return $filter('toArray')($rootScope.basket.seats[segmentNumber].passengers, true).some(function(passengerSeat) {
          return passengerSeat.underReserve;
        });
      }
    };

    return factory;
  }
]);
